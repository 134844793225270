import React, { useRef } from 'react';
import styled from 'styled-components';
import { useScrollProgress } from 'src/Utils/hooks';

import BlueprintBg from 'src/images/services/blueprint.png';

import SolidBg from './SolidBg';
import TransBg from './TransBg';
import LeftDescription from './LeftDescription';
import RightDescription from './RightDescription';
import Indicator from '../Indicator';

const ScrollContainer = styled.div`
  height: 200vh;
  position: relative;
  top: 0;
  left: 0;
`;

const MainContent = styled.div`
  background: url(${BlueprintBg});
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
`;

function DesignIntro(): JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scroll = useScrollProgress(containerRef);

  return (
    <ScrollContainer ref={containerRef}>
      <MainContent>
        <LeftDescription scroll={Math.min(scroll - 20, 50)} />
        <RightDescription scroll={Math.min(scroll - 20, 50)} />
        <SolidBg scroll={scroll} />
        <TransBg />
        <Indicator hidden={scroll >= 80} />
      </MainContent>
    </ScrollContainer>
  );
}

export default DesignIntro;
