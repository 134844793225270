import React from 'react';
import styled from 'styled-components';

import SolidBg from 'src/images/services/dreamreality-solid.png';
import MobileSolidBg from 'src/images/services/mobile-solid.png';

const SolidImage = styled.div`
  background: url(${MobileSolidBg});
  background-size: cover;
  background-position: center center;

  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;

  z-index: 2;

  @media only screen and (min-width: 1024px) {
    background: url(${SolidBg});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;

const ScrollBar = styled.div`
  background: grey;
  left: 0;
  position: absolute;

  transform: translateY(-2px);

  height: 4px;
  width: 100%;

  z-index: 3;
`;

interface ImageReveal {
  scroll: number;
}

function ImageReveal({ scroll }: ImageReveal): JSX.Element {
  const height = 100 - Math.min(scroll, 100);

  return (
    <>
      <ScrollBar style={{ top: `${height}%` }} />
      <SolidImage
        style={{
          clipPath: `polygon(
            0% ${height}%,
            100% ${height}%,
            100% 100%,
            0% 100%
          )`,
        }}
      />
    </>
  );
}

export default ImageReveal;
