import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  color: ${({ theme }) => theme.grey300};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 18px;
  text-align: right;

  position: absolute;
  padding: 2rem;

  transform: translate(0, 30%);

  width: calc(100% - 4rem);

  z-index: 10;

  display: none;

  @media only screen and (min-width: 1024px) {
    display: block;

    font-size: 20px;
    width: 300px;

    right: 16px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 24px;
    width: 350px;

    right: 32px;

    transform: translate(0, 50%);
  }

  @media only screen and (min-width: 1440px) {
    font-size: 28px;
    width: 400px;
  }

  @media only screen and (min-width: 1800px) {
    font-size: 36px;
    width: 600px;
  }
`;

interface Props {
  scroll: number;
}

const RightDescription = React.memo(({ scroll }: Props) => {
  return (
    <Content style={{ bottom: `${scroll}%` }}>
      Saber is here to provide you with solutions and a formal process,
      nurturing your idea from start to finish.
    </Content>
  );
});

export default RightDescription;
