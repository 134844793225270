import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  color: ${({ theme }) => theme.primary500};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 18px;

  display: none;

  position: absolute;
  padding: 2rem;

  transform: translate(0, 30%);

  width: calc(100% - 4rem);

  z-index: 10;

  @media only screen and (min-width: 1024px) {
    display: block;

    font-size: 20px;
    padding: 1rem;
    width: 300px;

    left: 16px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 24px;
    width: 450px;

    left: 32px;

    transform: translate(0, 50%);
  }

  @media only screen and (min-width: 1440px) {
    font-size: 28px;
    width: 500px;
  }

  @media only screen and (min-width: 1800px) {
    font-size: 36px;
    width: 600px;
  }
`;

interface Props {
  scroll: number;
}

const LeftDescription = React.memo(({ scroll }: Props) => {
  return (
    <Content style={{ bottom: `${scroll}%` }}>
      The design of a spacecraft and its mission are highly interrelated and a
      change in one part can have a cascading effect on the final solution. The
      challenge is to go from idea to a workable plan.
    </Content>
  );
});

export default LeftDescription;
