import React from 'react';
import styled from 'styled-components';

import TransBg from 'src/images/services/dreamreality-trans.png';
import MobileTransBg from 'src/images/services/mobile-trans.png';

const TransImage = styled.div`
  background: url(${MobileTransBg});
  background-size: cover;
  background-position: center center;

  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;

  will-change: transform;

  z-index: 1;

  @media only screen and (min-width: 1024px) {
    background: url(${TransBg});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;

const ImageReveal = React.memo(() => {
  return <TransImage />;
});

export default ImageReveal;
