import React from 'react';
import { Link } from 'gatsby';
import { Typography } from 'amphitheatre';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { StaticImage } from 'gatsby-plugin-image';

import * as Base from 'src/page-styles/base.styled';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import DesignIntro from 'src/Components/ScrollAnims/DesignIntro';
import LetsChat from 'src/Components/ServiceComponents/LetsChat';
import {
  BlueprintBg,
  Columns,
  LinkWrapper,
  Title,
  Paragraph,
} from 'src/Components/ServiceComponents/Options';
import SEO from 'src/Components/SEO';

const { Header } = Typography;

function MissionDesign(): JSX.Element {
  return (
    <>
      <SEO
        title="Mission design services | Saber Astronautics"
        description="Have a brilliant idea? From a back of the napkin sketch to your detailed, iterative, concurrent design, start your space mission with Saber."
        url="https://www.saberastro.com/mission-design"
      />
      <Layout>
        <DesignIntro />
        <Container.Dark>
          <Base.Gap />
          <Base.Text>
            <Header style={{ fontWeight: 'bold' }}>
              Why is design important?
            </Header>
            <Base.Paragraph>
              <Base.Yellow>
                The design document whether it’s a quick proposal or a detailed
                design is the vehicle of your ideas to the world. So it needs to
                be done right.
              </Base.Yellow>
            </Base.Paragraph>
            <Base.Paragraph>
              <Base.Yellow>
                Saber is experienced in writing initial proposals right through
                to the nitty gritty of detailed design, our heritage and role
                within the space industry is the expertise and connection you
                need to ensure your mission success.
              </Base.Yellow>
            </Base.Paragraph>
          </Base.Text>
          <Base.Gap />
        </Container.Dark>
        <BlueprintBg>
          <Columns>
            <LinkWrapper>
              <Link to="/pre-flight">
                <Title>
                  Already have a plan? <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph>Take a look at our Pre-Flight services.</Paragraph>
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to="/diagnostics">
                <Title>
                  Diagnostics <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph style={{ margin: 0 }}>
                  Take a look at our Diagnostics services.
                </Paragraph>
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to="/operations">
                <Title>
                  In Orbit? <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph>
                  Check out our flight and diagnostic services.
                </Paragraph>
              </Link>
            </LinkWrapper>
          </Columns>
        </BlueprintBg>
        <LetsChat
          image={
            <StaticImage
              className="background-image"
              src="../images/letschat1.jpg"
              alt="Rocket launching off"
            />
          }
        />
      </Layout>
    </>
  );
}

export default MissionDesign;
